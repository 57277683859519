.phone-mockup-container {
    position: relative;
    width: 100%;
    max-width: 900px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  .phone-mockup {
    width: 100%; /* Adjust the width to make the mockup bigger */
    max-width: 900px; /* Set a maximum width for the mockup */
    margin-left: -20%; /* Adjust the left margin to center the mockup */
    position: relative;
    background: url(./phone.png) no-repeat;
    padding-top: 180%; /* Set a padding-top to maintain the aspect ratio of the iPhone frame */
    background-size: contain; /* Make the background image fit within the container without stretching */
  }
  
  .your-image {
    position: absolute;
    top: 50%; /* Adjust the vertical position */
    left: 50%; /* Adjust the horizontal position */
    transform: translate(-50%, -50%); /* Center the image within the iPhone frame */
    max-width: 80%; /* Adjust the maximum width of the image */
    max-height: 80%; /* Adjust the maximum height of the image */
  }
  